<template>
  <div>
    <div class="d-flex justify-content-end flex-wrap mb-2">
      <b-form-group
        label="Sort"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-md-0 ml-2"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
          >
            <template #first>
              <option value="">
                none
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
          >
            <option :value="false">
              ASC
            </option>
            <option :value="true">
              DESC
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Search"
        label-size="sm"
        label-for="filterInput"
        class="mb-0 ml-2"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <b-table
      striped
      hover
      responsive 
      show-empty
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
    </b-table>

    <div class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BCol, BCard, BCardBody,
    BFormGroup, BInputGroup, BFormInputGroup, 
    BFormSelect, BFormInput, BInputGroupAppend,
    BPagination, BTable, BAvatar, BBadge, BButton,
    BDropdown, BDropdownItem
  } from 'bootstrap-vue'

  export default {
    components: {
      BCol, BCard, BCardBody,
      BFormGroup, BInputGroup, BFormInputGroup, 
      BFormSelect, BFormInput, BInputGroupAppend,
      BPagination, BTable, BAvatar, BBadge, BButton,
      BDropdown, BDropdownItem
    },
    data() {
      return {
        perPage: 50,
				pageOptions: [10, 50, 100],
				totalRows: 1,
				currentPage: 1,
				sortBy: '',
				sortDesc: false,
				sortDirection: 'asc',
				filter: null,
				filterOn: [],
				infoModal: {
					id: 'info-modal',
					title: '',
					content: '',
				},
				fields: [
					{ key: 'title', label: 'Title', sortable: true },
					{ key: 'link', label: 'Link' },
				],

        active_role: null
      }
    },
    computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      items() {
        let socmed = this.$store.state.member.socmed
        this.totalRows = socmed.length
        
        return socmed
      }
    },
    methods: {
			info(item, index, button) {
				this.infoModal.title = `Row index: ${index}`
				this.infoModal.content = JSON.stringify(item, null, 2)
				this.$root.$emit('bv::show::modal', this.infoModal.id, button)
			},
      onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length
				this.currentPage = 1
			},
    }
  }
</script>